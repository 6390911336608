// Custom theme
$accent-color: #f6324b;
$accent-secondary: #b5faf5;
$dark-primary-text: #b7b8cf;
$dark-secondary-text: #e3e3e3;
$gradient-dark1: #0a0e19;
$gradient-dark2: #091623;
$gradient-bg: $gradient-dark1, $gradient-dark2;

// mq
$xl-min: "min-width: 1700px";
$md-min: "min-width: 1200px";
$md-max: "max-width: 1199px";
$sm-min: "min-width: 600px";
$sm-max: "max-width: 599px";
