@import "src/theme/_theme-variables";
@import "src/theme/theme-variables";

.contact {
  background: url("/../public/map.png") no-repeat;
  background-size: cover;
  display: block;
  min-height: calc(100vh - 90px);

  h1 {
    text-align: center;
    color: #000;
    font-size: 50px;
    width: 100%;
    padding: 0 0 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #dfdfdf;
    @media ($sm-max) {
      font-size: 30px;
    }
  }
  
.container {
  overflow: hidden;
}

.contactForm {
  position: relative;
  max-width: 600px;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 30px;
  margin: 100px auto;
  box-shadow: 10px 10px 30px #0000006b;
}
.address {
  
  opacity: 0.7;
  line-height: 1.5;
  margin-bottom: 30px;
  font-weight: 500;
  
  &_item {
    font-size: 20px;
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
    
    svg {
      color: $accent-color;
      height: 28px;
      width: 28px;
      display: inline;
      position: absolute;
      top: 1px;
      left: 0;
    }
    a {
      color: $accent-color;
    }
    span {
      width: 90px;
      display: inline-block;
      font-weight: 600;
    }
    @media ($sm-max) {
      font-size: 17px;
      svg {
        width: 23px;
        top: 0;
      }
    }
  }
}
}
